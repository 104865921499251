/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import hexagono from "./hexagonogrande.png"

const BannerContact = () => {
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const [windowsWidth, setWindowsWidth] = useState(true)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 624) {
      setWindowsWidth(false)
    } else {
      setWindowsWidth(true)
    }
  }, [])

  return (
    <div className="container-banner-casos">
      {windowsWidth ? (
        <div className="container-banner-casos-hexagonos">
          <img
            src={hexagono}
            alt="icon-hexagono"
            className="container-banner-casos-hexagonos-hexagonoOne"
          />
          <img
            src={hexagono}
            alt="icon-hexagono"
            className="container-banner-casos-hexagonos-hexagonoTwo"
          />
        </div>
      ) : (
        ""
      )}
      <section className="container-banner-casos-info">

        <p className="container-banner-casos-info-title">
          <span>
            Casos de  éxito
            <svg
              style={{ bottom: "-2px" }}
              width="336" height="10" viewBox="0 0 336 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M62.8729 6.93133C54.8577 6.22282 5.56956 7.2179 2.05394 7.05476C-1.06468 6.80762 1.15811 2.1148 3.58702 1.40514C4.44818 1.15287 5.57034 1.09734 8.25126 1.20352C10.6026 1.29594 26.8097 1.13154 66.0179 0.806996C66.7853 0.952738 68.7273 0.464861 69.1865 0.642032C69.5368 0.745829 75.231 0.779023 83.6959 0.798535C93.1941 0.819567 119.051 0.808528 131.804 0.836349C150.624 0.871377 143.545 0.818107 165.091 0.91957C169.599 0.940039 166.077 1.19411 171.377 1.13633C182.68 1.0175 207.386 1.54619 215.941 1.41893C222.803 1.31648 224.298 1.32469 230.89 1.59526C236.164 1.81082 273.382 2.28756 277.584 2.06131C278.597 1.99073 279.366 2.04226 279.342 2.14513C279.782 2.16047 304.552 2.7933 304.661 2.88014C304.835 2.98587 305.582 3.05502 306.308 2.99825C307.629 2.90072 334.559 3.19691 334.929 3.5074C335.971 4.40201 331.368 7.95197 328.124 8.77098C324.726 9.62813 308.037 8.14322 293.697 8.39752C293.697 8.39752 289.653 8.31645 283.586 8.18812C224.348 6.97034 226.367 7.2464 215.063 7.36972C213.463 7.03241 207.407 7.71642 203.655 7.12805C202.098 6.8856 191.084 6.72593 188.619 6.91455C188.091 6.95625 184.178 6.91747 178.484 6.84839C172.812 6.77963 165.36 6.67608 157.819 6.61163C154.038 6.57924 150.234 6.54206 146.629 6.5212C143.001 6.49553 139.549 6.49484 136.493 6.49989C130.358 6.50968 125.784 6.56452 124.375 6.7146C113.192 6.11273 73.5611 6.77217 62.8729 6.93133Z" fill="#FFBA00" />
            </svg>
          </span> reales con Beex</p>
        <p className="container-banner-casos-info-text">Descubre cómo Beex han transformado la experiencia de atención, marketing, ventas y cobros de empresas líderes en diversas industrias.
          Explora historias reales de nuestros clientes y los resultados que han logrado con nuestra tecnología omnicanal impulsada con IA.</p>
      </section>
    </div>
  )
}
export default BannerContact
