//logo de empresas clientes
import Rimac from "@components/pageCasosdeExito/image/imageCards/FotoRimac.png"
import Eriza from "@components/pageCasosdeExito/image/imageCards/Foto3eriza.png"
import Ibr from "@components/pageCasosdeExito/image/imageCards/FotoIbr.png"
import Gyt from "@components/pageCasosdeExito/image/imageCards/FotoGTAsesores.png"


// logos testimonios
import AlbamarTes from "@components/sharedComponents/testimonies/images/logos/albamar.svg"
import CivaTes from "@components/sharedComponents/testimonies/images/logos/civa.svg"
import AunaTes from "@components/sharedComponents/testimonies/images/logos/auna.svg"
import CulqiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"

//foto testimonio desktop
import albamarDesktop from "@components/pageCasosdeExito/image/imagenTestimonios/desktop/albamar.png"
import civaDesktop from "@components/pageCasosdeExito/image/imagenTestimonios/desktop/civa.png"
import aunaDesktop from "@components/pageCasosdeExito/image/imagenTestimonios/desktop/auna.png"
import culqiDesktop from "@components/pageCasosdeExito/image/imagenTestimonios/desktop/culqi.png"
import astaraDesktop from "@components/pageCasosdeExito/image/imagenTestimonios/desktop/astara.png"

//foto testimonio desktop
import albamarMobile from "@components/pageCasosdeExito/image/imagenTestimonios/mobile/albamar.png"
import civaMobile from "@components/pageCasosdeExito/image/imagenTestimonios/mobile/civa.png"
import aunaMobile from "@components/pageCasosdeExito/image/imagenTestimonios/mobile/auna.png"
import culqiMobile from "@components/pageCasosdeExito/image/imagenTestimonios/mobile/culqi.png"
import astaraMobile from "@components/pageCasosdeExito/image/imagenTestimonios/mobile/astara.png"

const data = {
  products: [
    {
      image: Rimac,
      textbody:
        "Mira cómo ayudamos a su operación de telemarketing con Beex.",
      link: "/casos-de-exito/rimac/",
      tag: "Banca y Seguros | Telemarketing"
    },
    {
      image: Ibr,
      textbody:
        "Mira cómo aumentamos la contactabilidad de sus campañas con Beex.",
      link: "/casos-de-exito/ibr-peru/",
      tag: "Contact Center | Cobranzas"
    },
    {
      image: Eriza,
      textbody: "Descubre cómo potenciamos su operación de televentas con Beex.",
      link: "/casos-de-exito/3eriza/",
      tag: "Contact Center | Televentas "
    },
    {
      image: Gyt,
      textbody: "Descubre cómo ayudamos a su operación de cobranzas con Beex.",
      link: "/casos-de-exito/gt-asesores/",
      tag: "Contact Center | Cobranzas"
    },  
  ],
  testimonies: [
    {
        image: CulqiTes,
        title:
            "Culqi aumentó en 8x sus ventas de mpos al mes con Beex Contact Center",
        textbody:
            "Con llamadas predictivas, mensajería en WhatsApp y automatizando el contacto con Secuencias, Culqi potenció su operación de telemarketing.",
        name: "Paul Vera",
        post: "Jefe de Telemarketing de Culqi",
        link: "/casos-de-exito/culqi/",
        imgDesktop: culqiDesktop,
        imgMobile: culqiMobile,
        metrics: [
            {
                metric: "+65%",
                descripcion: "mejoró su contactabilidad con sus leads",
                color: "#FFB800",
            },
            {
                metric: "x8",
                descripcion: "aumentó sus ventas mensuales",
                color: "#5831D8",
            },
            {
                metric: "x3",
                descripcion: "redujo el tiempo de recorrido de bases",
                color: "#FFB800",
            },
        ],
    },

    {
        image: AunaTes,
        title:
            "AUNA mejoró su tasa de conversión de leads en 5pp con Beex Conversations",
        textbody:
            "Con presencia en Latam, AUNA ha automatizado su gestión de leads gracias a WhatsApp y la integración con su CRM.",
        name: "Graciela Zapater Espejo",
        post: "Especialista Digital de AUNA",
        link: "/casos-de-exito/auna/",
        imgDesktop: aunaDesktop,
        imgMobile: aunaMobile,
        metrics: [
            {
                metric: "5pp",
                descripcion: " mejoró su tasa de conversión de leads",
                color: "#FFB800",
            },
        ],
    },
    {
        image: AstaraTes,
        title: "Astara logró reducir su esfuerzo de remarketing en 93% con Beex",
        textbody:
            "Con ventas de marcas como Kia, Mitsubishi y más, Astara incrementó sus ventas de automóviles en 3x con Beex Conversations.",
        name: "Jonny Castillo",
        post: "Jefe Comercial de Astara",
        link: "/casos-de-exito/astara/",
        imgDesktop: astaraDesktop,
        imgMobile: astaraMobile,
        metrics: [
            {
                metric: "x3",
                descripcion: "aumentó sus ventas digitales",
                color: "#FFB800",
            },
            {
                metric: "93%",
                descripcion: "redujo su esfuerzo para remarketing",
                color: "#5831D8",
            },
            {
                metric: "80%",
                descripcion: "mejoró contactabilidad con leads",
                color: "#FFB800",
            },
        ],
    },
    {
        image: AlbamarTes,
        title: "Albamar Inmobiliara mejoró en +75% su conversión de citas a visitas con Beex",
        textbody:
            "Con la integración de Beex Contact Center y su CRM, Albamar ha automatizado el contacto con sus interesados y mejorar su marketing y ventas.",
        name: "Luis Aroni Huamán",
        post: "Jefe de Contact Center de Albamar",
        link: "/casos-de-exito/albamar/",
        imgDesktop: albamarDesktop,
        imgMobile: albamarMobile,
        metrics: [
            {
                metric: "75%",
                descripcion: "conversión de citas a visitas ",
                color: "#FFB800",
            },
            {
                metric: "+60%",
                descripcion: "mejora en tasa de recontacto ",
                color: "#5831D8",
            },
            {
                metric: "100%",
                descripcion: "clientes atendidos por voz y WhatsApp ",
                color: "#FFB800",
            },
        ],
    },
    {
        image: CivaTes,
        title: "CIVA redujo en +20% el tiempo de respuesta con sus pasajeros con la ayuda de Beex",
        textbody:
            "Con la gestión omnicanal completa de Beex, CIVA mejora la atención con sus pasajeros por llamadas, redes sociales y correo electrónico.",
        name: "Ángel Rodas",
        post: "Coordinador de Ventas Digital de CIVA",
        link: "/casos-de-exito/civa/",
        imgDesktop: civaDesktop,
        imgMobile: civaMobile,
        metrics: [
            {
                metric: "+8%",
                descripcion: " aumento de ventas digitales",
                color: "#FFB800",
            },
            {
                metric: "20%",
                descripcion: "reducción en tiempos de respuesta",
                color: "#5831D8",
            },
            {
                metric: "1 min",
                descripcion: "reducción en Tiempo Medio Operativo",
                color: "#FFB800",
            },
        ],
    },
],
}

export default data
